import styles from './Home.module.scss';
import { ExitIcon } from "../../assets";
import { useContext, useEffect } from "react";
import bannerImage from '../../assets/Uli fotos/_MG_1699.jpg';

import FacialSection from '../../assets/TratamentosImages/Facial/LimpezaDePele.jpg';
import CorporalSection from '../../assets/TratamentosImages/Corporal/Massagem.jpg';
import PosOperatorioSection from '../../assets/TratamentosImages/PosOperatorio/PosOperatorioSection.jpg';

import Summary from "../../components/SummaryPresentation/Summary";
import ModalAdd from "../../components/Modal/ModalAddOff/ModalAddOff";
import summary_image from "../../assets/Uli fotos/IMG_0769-HDR - feed.jpg";

import { ContextModalAddOff } from "../../Contexts/OffContexts/ModalAddOff";
import { ContextModalDeleteOff } from "../../Contexts/OffContexts/ModalDeleteOff";
import { SectionTitle, MainBanner, TextBanner, Carousel, TratamentoItem, NavBar, Footer, ModalDeleteOFF, Pagination } from "../../components";


const Home = () => {
    const tratamentoFacial = "Realce sua beleza natural com nossos tratamentos faciais rejuvenescedores. Descubra uma jornada de cuidados personalizados para a pele, restaurando o brilho e a vitalidade. Deixe-nos cuidar de você e revelar sua melhor versão."
    const tratamentoCorporal = "Aprimore sua harmonia corporal com nossos tratamentos revitalizantes. Explore uma experiência de cuidados personalizados para o corpo, revitalizando a vitalidade e o equilíbrio. Confie em nós para cuidar de você, revelando a sua expressão mais radiante."
    const tratamentoPosOperatorio = "Recupere-se plenamente com nossos tratamentos especializados para a fase pós-operatória. Explore uma jornada de cuidados personalizados, impulsionando sua recuperação e promovendo o bem-estar. Confie em nós para guiá-lo durante o processo de reabilitação, revelando sua melhor versão após a intervenção."
    
    const tratamentosItems = [
        <TratamentoItem ideiaImage={"secção de tratamentos faciais"} title={"Faciais"} linkImage={FacialSection} description={tratamentoFacial} routerSegmented={"/tratamentos/facial"}/>, 
        <TratamentoItem ideiaImage={"secção de tratamentos corporais"} title={"Corporais"} linkImage={CorporalSection} description={tratamentoCorporal} routerSegmented={"/tratamentos/corporal"}/>, 
        <TratamentoItem ideiaImage={"secção de tratamentos pós-operatórios"} title={"Pós-Operatórios"} linkImage={PosOperatorioSection} description={tratamentoPosOperatorio} routerSegmented={"/tratamentos/posOperatorio"}/>,
    ];

    const ContextModalAdd = useContext(ContextModalAddOff);
    const ContextModalDelete = useContext(ContextModalDeleteOff);

    // UseEffect responsável por desabilitar o scroll quando um modal for renderizado
    useEffect( () => {
        if( ContextModalAdd !== undefined && ContextModalAdd.renderModal ){
            document.body.style.overflow = 'hidden';
            return
        }
        if( ContextModalDelete !== undefined && ContextModalDelete.renderModal ){
            document.body.style.overflow = 'hidden';
            return
        }

        document.body.style.overflow = 'unset';
    }, [ContextModalAdd, ContextModalDelete ]);

    return(
        <>
            <ModalAdd />
            <ModalDeleteOFF />
            <NavBar />
            <MainBanner wppButton imageUrl={bannerImage} theme={"dark"}>
                Seja Bem-vindo à<br/><span>Uliclinic</span>
            </MainBanner>
            
            <Summary />
            
            <TextBanner imageUrl={summary_image} theme={"light"}>
                A Uli Clinic oferece uma variedade de procedimentos para ajudar as pessoas a se sentirem mais confiantes e satisfeitas com sua aparência
            </TextBanner>
            
            <SectionTitle title="Uliclinic OFF">
                <button className={styles["AddPromocao"]} 
                        onClick={() => ContextModalAdd?.setRenderModal({type : "add", id : undefined, renderModal: true})}>
                    <ExitIcon />
                    <span>Adicionar</span>
                </button>
            </SectionTitle>
            
            <Carousel />
            
            <SectionTitle title="Tratamentos"/>
            <Pagination items={tratamentosItems} elementsPerPage={3}/>
            <Footer/>
        </>
    )
}

export default Home;