import styles from './WhatsLink.module.scss'
import { WhatsappLogo } from '../../assets'


const WhatsLogo = () => {
    return(
        <a href='https://api.whatsapp.com/send?phone=5585991791916&text=Oi,%20eu%20queria%20marcar%20uma%20avalia%C3%A7%C3%A3o%20com%20voc%C3%AAs%20%F0%9F%A4%A9' target='_blanck' onClick={() => window.scroll(0,0)}>
            <div className={styles['WhatsLogoContainer']}>
                <div className={styles['WhatsLogoContent']}>
                    <WhatsappLogo />
                </div>
            </div>
        </a>
    )
}

export default WhatsLogo;