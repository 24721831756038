import styles from './TextBanner.module.scss'


type BannerProps = {
    children: any,
    imageUrl: string,
    theme: 'dark' | 'light' | 'transparent'
}

/**
 * @param imageUrl - It must receive a variable from an import or an url from the web:
 * 
 * ```js
 * import image from "/assets/Logos/Logo1.png";
 * const url = "https://example.com";
 * ``` 
 * 
 * @param children - It is the text that is going to be displayed. 
 * 
 * Use the tag`<span></span>` to change from the default font (Hero Regular) to Agne Regular.
 * 
 * This component only works properly for the `max-height:608px` beyond this height the entire background will not become darker only the 608px in the middle.
 */

const TextBanner = (props: BannerProps) => {
    const theme = {
        "backgroundColor": {
            "dark": "#473527",
            "light": "#F7E7CE",
            "transparent": "transparent"
        },
        "fontColor": {
            "dark": "#F7E7CE",
            "light": "#473527",
            "transparent": "transparent"
        }
    }

    const PropsStyle = {
        backgroundImage: `url("${props.imageUrl}")`,
        color: theme.fontColor[props.theme],
    }

    return(
        <div className={styles['MainContainer']} style={PropsStyle}>
            <div className={styles['MainPicture']} style={{backgroundColor: theme.backgroundColor[props.theme]}}></div>
            <div className={styles['MainTitle']} style={{marginTop: 0}}>{props.children}</div>
        </div>
    )    
}

export default TextBanner;