import { createContext } from "react";


export type ModalItemAddT = {
    id?: number;
    renderModal?: boolean
    type?: "add" | "edit"
} | undefined

export interface ModalDeleteAddI{
    setRenderModal: React.Dispatch<ModalItemAddT>,
}

export const ContextModalAddOff = createContext< ModalDeleteAddI & ModalItemAddT | undefined>(undefined);
