import BotoxImage from '../../../assets/TratamentosImages/Facial/Botox.jpg';
import PellingImage from '../../../assets/TratamentosImages/Facial/Pelling.jpg';
import SkinBooterImage from '../../../assets/TratamentosImages/Facial/skinbooster.png';
import SobrancelhaImage from '../../../assets/TratamentosImages/Facial/Sobrancelhas.jpg';
import PreechimentoImage from '../../../assets/TratamentosImages/Facial/Preenchimento.jpg';
import LimpezaDePeleImage from '../../../assets/TratamentosImages/Facial/LimpezaDePele.jpg';
import MicroAgulhamentoImage from '../../../assets/TratamentosImages/Facial/MicroAgulhamento.jpg';

import PigmentacaoLabial from '../../../assets/TratamentosImages/Facial/IMG_2322.jpg';
import BrowsLamination from '../../../assets/TratamentosImages/Facial/BrowsLift.jpg';
import PigmentacaoSobrancelha from '../../../assets/TratamentosImages/Facial/Fios sobrancelha.jpg';
import RadiofrequenciaImage from '../../../assets/TratamentosImages/Facial/RadioFrequenciaRosto.jpg';

import { SectionTitle, ServiceItem, Pagination, NavBar, Footer } from "../../../components";


const Tratamentos = () => {
    const elements = 9;
    
    const TratamentosItems = [
        <ServiceItem ideia={"Foto do serviço de radiofrequencia"}title={"Radiofrequência Facial"}imageLink={RadiofrequenciaImage} description={"Aparelho de emissão de correntes de alta frequência que promove aquecimento tecidual chegando até 42 graus estimulando assim a produção de colágeno.  Indicada para o tratamento de rugas, flacidez, celulite, estrias, cicatrizes e gordura localizada. "} />,
        <ServiceItem title={"Limpeza de pele "}     imageLink={LimpezaDePeleImage}  description={"O objetivo da limpeza de pele é remover impurezas e células mortas. Assim é possível reduzir a oleosidade, diminuindo o aparecimento de acnes e milium, aumentar a hidratação e uniformizar a pele. O protocolo inclui a higienização da pele, esfoliação, peeling de diamante, extração dos cravos, finalizando com alta frequência e máscara específica para a necessidade da sua pele."}/>, 
        <ServiceItem title={"Brow + Lift"}             imageLink={BrowsLamination} description={"Procedimentos especializados que usam produtos químicos e técnicas físicas para aprimorar os olhos. O Brow Lamination remodela e fixa as sobrancelhas, proporcionando definição. Simultaneamente, o lifting de cílios curva e eleva, realçando o olhar. A combinação oferece sobrancelhas disciplinadas e cílios naturalmente elevados, promovendo uma expressão facial marcante."} />,
        <ServiceItem title={"Peeling"}              imageLink={PellingImage} description={"Revitalize sua pele e conquiste um brilho saudável com o nosso tratamento de peeling. Essa técnica inovadora remove suavemente as camadas superficiais da pele, promovendo a renovação celular e estimulando a produção de colágeno. Indicado para diversos tipos de pele, o peeling é uma opção eficaz para melhorar a textura, reduzir manchas e minimizar imperfeições."} />, 
        <ServiceItem title={"Botox"}                imageLink={BotoxImage} description={"Desfrute de uma aparência rejuvenescida e expressões suavizadas com o nosso tratamento de Botox. Utilizando uma abordagem segura e eficaz, a toxina botulínica é aplicada para relaxar temporariamente os músculos responsáveis por linhas de expressão e rugas. Resultados notáveis podem ser alcançados, proporcionando uma aparência mais descansada e jovial."} />, 
        <ServiceItem title={"Pigmentação Sobrancelha"} imageLink={PigmentacaoSobrancelha} description={"Procedimento especializado que usa pigmentos e técnicas para melhorar as sobrancelhas. A Pigmentação de Sobrancelha utiliza pigmentos específicos para definição e preenchimento, corrigindo assimetrias. O objetivo é alcançar sobrancelhas naturais, duradouras e esteticamente agradáveis, promovendo um visual expressivo e harmonioso."} />,
        <ServiceItem title={"Microagulhamento"}     imageLink={MicroAgulhamentoImage}  description={"O microagulhamento é um tratamento estético que, através da estimulação feita com micro agulhas que penetram na pele, favorece a formação de novas fibras de colágeno, que dão firmeza e sustentação à pele. Indicado para tratar cicatrizes de acne, redução de poros dilatados, redução de manchas, rugas e linhas de expressão. "} />, 
        <ServiceItem title={"Pigmentação Labial"}      imageLink={PigmentacaoLabial} description={"Trata-se de substâncias químicas ou físicas (pigmentos naturais) projetadas para intensificar a cor dos lábios, proporcionando um visual vibrante e duradouro. Esses elementos buscam realçar a tonalidade, conferir uniformidade e garantir uma aparência natural, ao mesmo tempo em que oferecem hidratação e proteção labial."} />,
        <ServiceItem title={"Skinbooster"}          imageLink={SkinBooterImage} description={"Desperte a luminosidade natural da sua pele com nosso avançado tratamento de Skinbooster. Combinando ingredientes nutritivos e ácido hialurônico de alta qualidade, essa terapia revitalizante promove uma hidratação profunda, restaurando a elasticidade e suavizando linhas finas. Indicado para todos os tipos de pele."} />, 
        <ServiceItem title={"Preenchimento facial"} imageLink={PreechimentoImage} description={"Descubra a beleza da plenitude e definição com nosso serviço de preenchimento facial. Utilizando ácido hialurônico de qualidade premium, nosso procedimento é projetado para suavizar linhas, restaurar volume e realçar contornos faciais de maneira natural. Com resultados imediatos e duradouros, o preenchimento facial é uma escolha confiável."} />, 
        <ServiceItem title={"Micropigmentação de sobrancelhas e lábios"} imageLink={SobrancelhaImage} description={"A Micropigmentação de Sobrancelhas e Lábios é um método especializado para realçar sua beleza com naturalidade e durabilidade. Nossos profissionais habilidosos utilizam técnicas precisas, proporcionando contornos aprimorados e resultados impecáveis. Descubra a definição perfeita que destaca sua singularidade com elegância duradoura."} />, 
    ]

    return(
        <>
            <NavBar/>
            <SectionTitle title={"Facial"}/>
            <div style={{margin: "25px 0"}}>
                <Pagination items={TratamentosItems} elementsPerPage={elements}/>
            </div>
            <Footer/>
        </>
    )
}

export default Tratamentos