import { createContext } from "react";

export type ModalItemDeleteT = {
    id?: number;
    renderModal?: boolean
} | undefined

export interface ModalDeleteOffI{
    setRenderModal: React.Dispatch<ModalItemDeleteT>,
}

export const ContextModalDeleteOff = createContext< ModalDeleteOffI & ModalItemDeleteT | undefined >(undefined);