import styles from './MainBanner.module.scss'
import wpplogo from '../../assets/Logos/WhatsappLogo/WhatsappLogo.svg'


type BannerProps = {
    children: any,
    height?: string,
    imageUrl: string,
    wppButton: boolean,
    bkgdCover?: "cover" | "auto",
    theme: 'dark' | 'light' | 'transparent',
}

const WhatsappButton = () => {

    return(
        <a className={styles['WhatsappContainer']} href={'https://api.whatsapp.com/send?phone=5585991791916&text=Oi,%20eu%20queria%20marcar%20uma%20avalia%C3%A7%C3%A3o%20com%20voc%C3%AAs%20%F0%9F%A4%A9'} target='blank' onClick={() => window.scroll(0,0)}>
            <span className={styles['Title']}>QUERO MARCAR UM HORÁRIO</span>

            <span className={styles['Logo']}>
                <img className={styles['SvgLogo']} src={wpplogo} alt="Whatsapp Logo"/> 
            </span>
        </a>
    )
}

/**
 * @param imageUrl - It must receive a variable from an import or an url from the web:
 * 
 * ```js
 * import image from "/assets/Logos/Logo1.png";
 * const url = "https://example.com";
 * ``` 
 * 
 * @param children - It is the text that is going to be displayed. 
 * 
 * Use the tag`<span></span>` to change from the default font (Hero Regular) to Agne Regular.
 * 
 * This component only works properly for the `max-height:608px` beyond this height the entire background will not become darker only the 608px in the middle.
 */

const MainBanner = (props: BannerProps) => {
    const theme = {
        "backgroundColor": {
            "dark": "#473527",
            "light": "#F7E7CE",
            "transparent": "transparent"
        },
        "fontColor": {
            "dark": "#F7E7CE",
            "light": "#473527",
            "transparent": "transparent"
        }
    }

    return(
        <div className={styles['MainContainer']} 
            style={{backgroundImage: `url("${props.imageUrl}")`, color: theme.fontColor[props.theme], minHeight: props.height, backgroundSize: props.bkgdCover}}>
            <div className={styles['MainPicture']} style={{backgroundColor: theme.backgroundColor[props.theme]}}></div>
            <div className={styles['MainTitle']}>{props.children}</div>
            {props.wppButton && <WhatsappButton/>}
        </div>
    )
}

export default MainBanner