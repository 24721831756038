import styles from './Footer.module.scss';
import { AltLogo, Calendar, Instagram, InstagramFooter, CallRemove, Email, Map1 } from '../../assets'

const Footer = () => {
    return(
        <>
        <div className={styles["Container"]}>
            <div className={styles["Logo"]}>
                <AltLogo/>
            </div>

            <div className={styles["InstagramMobile"]}>
                <a href={"https://www.instagram.com/uliclinic/"} target={'blank'} onClick={() => window.scroll(0,0)}><InstagramFooter/></a>
            </div>

            <div className={styles["List"]}>
                <ul>
                    <li className={styles["ListTitle"]}>Links Úteis</li>
                    <li><a href={"/"} onClick={() => window.scroll(0,0)}>Home</a></li>
                    <li><a href={"/clinica"} onClick={() => window.scroll(0,0)}>Clínica</a></li>
                    <li><a href={"/equipeuli"} onClick={() => window.scroll(0,0)}>Equipe Uli</a></li>
                    <li><a href={"/tratamentos"} onClick={() => window.scroll(0,0)}>Tratamentos</a></li>
                    <li><a href={"/onde-estamos"} onClick={() => window.scroll(0,0)}>Onde Estamos</a></li>
                </ul>
            </div>

            <div className={styles["List"]}>
                <ul>
                    <li className={styles["ListTitle"]}>Informações</li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <Instagram/>
                            <span><a href="https://www.instagram.com/uliclinic/" target='blank' onClick={() => window.scroll(0,0)}>@uliclinic</a></span>
                        </div>
                    </li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <CallRemove/>
                            <span>(85) 99179-1916</span>
                        </div>
                    </li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <Email/>
                            <span>uliclinicfortaleza@gmail.com</span>
                        </div>
                    </li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <Map1/>
                            <span>Uli Clinic, Rua Marcos Macêdo, 1333 - sa <br/> 1819 - Aldeota, Fortaleza - CE, 60150-190</span>
                        </div>
                    </li>
                </ul>
            </div>
        
            <div className={styles["List"]}>
                <ul>
                    <li className={styles["ListTitle"]}>Funcionamento</li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <Calendar/>
                            <span className={styles["FuncText"]}>Sábado: Fechado</span>
                        </div>
                    </li>
                    
                    <li>
                        <div className={styles["FuncElement"]}>
                            <Calendar/>
                            <span className={styles["FuncText"]}>Seg. a Sexta: 08:00 às 18:00</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Footer