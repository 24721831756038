import { useContext } from 'react';
import { FormContext } from '../Form';


interface InputI{
    value_input : any,
    classname : string,
    fieldName : string,
    placeholder? : string,
    IconRigth?: JSX.Element,
    StyleObject : {[key: string] : any},
    type : 'text' | 'password' | 'email',
    onChangeValue? : (e: React.SyntheticEvent, key? : string) => void;
}

const Input = (props : InputI) => {

    const { 
        IconRigth,  classname, 
        fieldName,  value_input,
        StyleObject,onChangeValue,
        ...rest
    } = props

    const { dispatch } = useContext(FormContext);

    const handleChangeValue = ( ev : React.SyntheticEvent ) => {
        let fieldValu = (ev.target as HTMLInputElement).value;

        if ( fieldValu !== null && fieldName !== null ){
            
            dispatch({
                type: "SET_FORM", 
                payload: {
                    field: fieldName,
                    value: fieldValu
                }
            });

            if( props.onChangeValue !== undefined ) {
                props.onChangeValue(ev, fieldName)
            }
        }
    }

    return(
        <div className={StyleObject[classname]}>
            <input {...rest} 
                autoComplete="on"
                value={value_input}
                name={props.fieldName}
                onChange={(ev) => handleChangeValue(ev)} />
            {IconRigth !== undefined ? IconRigth : <></>}
        </div>
    )
}

export default Input;