import { Link } from 'react-router-dom';
import styles from './tratamento.module.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';


interface TratamentoItemProps{
    title: string,
    linkImage: string,
    ideiaImage: string,
    description: string,
    routerSegmented : string,
}

/**
 * É o componente que representa os segmentos de tratamentos ofertados.
 * 
 * @param title é o nome do tratamento.
 * @param linkImage é o link da image de tratamento.
 * @param description é toda a descrição do tratamento ofertado.
 * @param routerSegmented é o link para uma rota de tratamentos específicos.
 * @param ideiaImage é uma alternativa ao load da imagem (acesibilidade)
 * @returns Retorna um JSX.Element personalizado ja com o css.
 */


const TratamentoItem = ( { title, linkImage, routerSegmented, description, ideiaImage } : TratamentoItemProps) => {
    return(
        <div className={styles['TratamentoContainer']}>
            <LazyLoadImage 
                effect="blur"
                loading='lazy'
                src={linkImage} className={styles["ImageProps"]} alt={ideiaImage}/>
                
            <div className={styles['ImageDescribeContainer']}>
                <div className={styles['titleStyles']}>{title}</div>
                <div className={styles['descriptionStyles']}>{description}</div>
                <Link to={routerSegmented} className={styles['LinkStyles']} onClick={() => window.scroll(0,0)}>Leia Mais</Link>
            </div>
        </div>
    )
}

export default TratamentoItem;