import styles from './Toast.module.scss';
import { AuthContext } from '../../Contexts';
import { StyleComponentToast } from './types';
import { useContext, useEffect, useState } from 'react';
import { toastMessageType } from '../../Contexts/types';
import { CloseIcon, DangerIcon, SuccessIcon } from '../../assets';


/**
 * Componente de feedback para o usuário do sistema.
 * 
 * @param id representa o identificador daquele componente.
 * @param message é a mensagem a ser exibida para o usuario.
 * @param type define o tipo do toast que aparecerá no canto superior direito
 * 
 * exemplo de uso é chamar o toast
 * `
 *   <Toast id={0} message={"Tio Sam Socorro"} type={"error"} key={index} />
 * `
 * irá renderizar um toast com o background vermelho com a mensagem enviada !
 * 
 */

const Toast = (props : (toastMessageType & {id : number}) ) => {

    const [ visible, setVisible ] = useState(false);
    const { removeToast } = useContext(AuthContext);


    useEffect(() => {
        setVisible(true);

        const timeout = setTimeout( () => {
            setVisible(false);
            removeToast(props.id);
        }, 3000);

        return () => clearTimeout(timeout);
        
    }, [props.id, removeToast]);

    const styleToastContainer : StyleComponentToast ={
        error : {
            Label: "Error",
            Icon : <CloseIcon  width={20} height={20}/>,
            style : {
                color : "white",
                background : "#C02222",
            }
        },
        success : {
            Label : "Sucess",
            Icon : <SuccessIcon width={20} height={20}/>,
            style : {
                color : "white",
                background : "#06D6A0",
            }
        },
        warning : {
            Label : "Warning",
            Icon : <DangerIcon  width={20} height={20}/>,
            style : {
                color : "white",
                background : "#e06818",
            }
        }
    }
    
    return(
        <div onClick={ () => removeToast(props.id) }
            className={styles[`${ visible ? 'ToastItemShow' : 'ToastItemHide'}`]} style={styleToastContainer[props.type].style}>
            {styleToastContainer[props.type].Icon}{props.message}
        </div>
    )
}

const ToastContainer = () => {

    const { toasts } = useContext( AuthContext );

    return(
        <section className={styles["ToastContainer"]}>
            {   toasts.map( ( toast : toastMessageType, index ) => {return(
                    <Toast id={index} message={toast.message} type={toast.type} key={index} />
            )} )}
        </section>
    )
}


export default ToastContainer;