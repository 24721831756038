import { Api } from '../services';
import { createContext, useState, useEffect, useCallback } from 'react';
import { AuthContextProviderProps, AuthContextType, AuthDataType, toastMessageType } from './types';

import jwtDecode from 'jwt-decode';
import { AxiosRequestConfig } from 'axios';


const defaultValue : AuthContextType = { 
    user: null,
    toasts: [],
    loading: false,
    login: () => {},
    logout: () => {},
    addToast: () => {},
    authenticated: false, 
    removeToast: () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultValue);

export const AuthContextProvider = ({children}: AuthContextProviderProps) => {

    const [ user, setUser ] = useState<AuthDataType>(null);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ alertMessages, setAlertMessages ] = useState<toastMessageType[]>([]);

    const handleRequestInterceptor: any = useCallback(async (config: AxiosRequestConfig<any>) => {
        const userData = localStorage.getItem("user_data");
        
        if ( userData ) {
            const data: AuthDataType = JSON.parse(userData);
            
            if ( data?.accessToken !== undefined ) {
                const now = Math.floor(Date.now() / 1000);
                const actoken = jwtDecode<any>(data.accessToken);
                const rftoken = jwtDecode<any>(data.refreshToken);
                
                if (actoken.exp < now && config.url !== '/refresh') {
                    if (rftoken.exp < now) {
                        logout();
                    } else {
                        await Api.get("/refresh", {headers: {Authorization: `Bearer ${data.refreshToken}`}})
                        .then((res) => {
                            if (res.data.access_token) {
                                data.accessToken = res.data.access_token;
                                localStorage.setItem("user_data", JSON.stringify(data));

                                config.headers = {
                                    ...config.headers,
                                    authorization: `Bearer ${data.accessToken}`,
                                  };

                                Api.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
                            } else {
                                logout();
                            }
                        });
                    }
                }
            }
        }

        return config;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const userData = localStorage.getItem("user_data");
        
        if (userData) {
            const data: AuthDataType = JSON.parse(userData);
            Api.defaults.headers.Authorization = `Bearer ${data?.accessToken}`;
            Api.interceptors.request.use(handleRequestInterceptor);
            setUser(data);
        }

        setLoading(false);
    }, [handleRequestInterceptor]);

    const login = (userData : AuthDataType) => {
        setUser(userData);
        
        if ( userData?.accessToken !== undefined ) {
            localStorage.setItem("user_data", JSON.stringify(userData));
            
            Api.defaults.headers.Authorization = `Bearer ${userData?.accessToken}`;
            Api.interceptors.request.use(handleRequestInterceptor);
            return true
        }
        
        return false
    }

    const logout = () => {
        
        setUser(null);
        localStorage.removeItem("user_data");
        Api.defaults.headers.Authorization = null;
        addToast({message: "Você saiu do sistema !", type: 'success'})
    }
    
    const addToast = ( toast: toastMessageType ) => {
        setAlertMessages( alertMessages => [...alertMessages, toast] )
    }

    const removeToast = ( id : number ) => {

        const remove_msg = (index : number) => {
            return index !== id
        }

        setAlertMessages( alertMessages => alertMessages.filter( (toast , index) => remove_msg(index) ));
    }

    const initialValueContext : AuthContextType = {
        user: user,
        login: login,
        logout: logout,
        loading: loading,
        addToast: addToast,
        toasts: alertMessages,
        authenticated: !!user,
        removeToast: removeToast,
    }

    return (
        <AuthContext.Provider value={ initialValueContext } >
            { children }
        </AuthContext.Provider>
    );
}