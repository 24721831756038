import { useContext } from "react";
import styles from './modal.module.scss';
import { ContextModalDeleteOff } from "../../../Contexts/OffContexts/ModalDeleteOff";
import { Api } from "../../../services";
import { AuthContext } from "../../../Contexts";


const ModalDeleteOff = () => {

    const { addToast } = useContext(AuthContext);
    const ModalContextItem = useContext(ContextModalDeleteOff);

    const handleDeleteOff = async () => {
        await Api.delete(`/image/delete/${ModalContextItem?.id}`).then( (response) => {

            addToast({message : "Oferta deletada com sucesso !", type : "success"});
            ModalContextItem?.setRenderModal(undefined);

        }).catch( () => {
            
            addToast({message : "Não foi possível deletar a oferta !!", type : "error"});
        } )
    }

    return (
        <>
        {   ModalContextItem?.renderModal && (
            <div className={styles["ModalContainer"]}>
                <div className={styles["ModalContent"]}>
                    <div className={styles["ModalHeader"]}>
                        <span>Você tem certeza que deseja remover esse dado?</span>
                        <span>Não será possível reverter essa ação !</span>
                    </div>
                    <div className={styles["ModalButtons"]}>
                        <button className={styles["DeleteButton"]} onClick={handleDeleteOff}>Remover</button>
                        <button className={styles["CancelButton"]} onClick={() => ModalContextItem.setRenderModal(undefined)}>Cancelar</button>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default ModalDeleteOff;