import { Api } from '../../services';
import React, { createContext, useReducer, useState } from 'react';
import { FormContextType, FormProps, FormReducerType, FormStateType } from './types';


export const FormContext = createContext<FormContextType>({
    hasError: false,
    dispatch: () => {},
    state: {fields: {}},
    setHasError: () => {},
});

const reducer = (state: FormStateType, action: FormReducerType) => {
    switch(action.type) {
        case 'SET_FORM':

            if (action.payload.field !== undefined && action.payload.value !== undefined){
                state.fields[action.payload.field] = action.payload.value;
            }

            return state;
        case 'SET_FIELD_ERRORS':
            
            if (action.payload.errors !== undefined && state.errors) {
            
                if (action.payload.errors.fields !== undefined) {
                    state.errors["fields"] = action.payload.errors.fields;
                }
            
                if (action.payload.errors.form !== undefined) {
                    state.errors["form"] = action.payload.errors.form;
                }
            }
            return state;

        default:
            return state;
    }
};

const Form = (props: FormProps) => {
    const [hasError, setHasError] = useState(false);
    const [state, dispatch] = useReducer(reducer, {fields: {}, errors: {}});

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        Api.post(props.url, state.fields)
            .then( props.onAfterSubmit !== undefined ? props.onAfterSubmit : (res: any) => {
                
                if (props.additionalMethods !== undefined && !res.data.error) {
                    props.additionalMethods(res.data);
                }
                            
            }).catch( (err) => {

                if ( props.onSubmitError !== undefined ){
                    props.onSubmitError( err )
                }
            }
        );
    }

    const initialValueContext: FormContextType = {
        state: state,
        dispatch,
        hasError,
        setHasError,
    }

    return (
        <FormContext.Provider value={ initialValueContext } >
            <form onSubmit={ props.onSubmit === undefined ? handleSubmit : props.onSubmit }>{ props.children }</form>
        </FormContext.Provider>
    )
}

export default Form;