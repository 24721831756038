import styles from './ClinicaStyle.module.scss';
import { SectionTitle, MapSection } from "../../components";
import { Carousel, Footer, MainBanner, NavBar } from "../../components";

import BannerScreenPng from "../../assets/Uli fotos/IMG_0749-HDR - feed.jpg";
import ClinicaFeed1 from '../../assets/Uli fotos/IMG_0687 - feed.jpg';
import ClinicaFeed2 from '../../assets/Uli fotos/IMG_0701-HDR - feed.jpg';
import ClinicaFeed3 from '../../assets/Uli fotos/IMG_0752-HDR - feed.jpg';
import ClinicaFeed4 from '../../assets/Uli fotos/IMG_0763-HDR - feed.jpg';
import ClinicaFeed5 from '../../assets/Uli fotos/IMG_0799 - feed.jpg';
import ClinicaFeed6 from '../../assets/Uli fotos/IMG_0704 - feed.jpg';
import ClinicaFeed7 from '../../assets/Uli fotos/IMG_0692 - feed.jpg';
import ClinicaFeed8 from '../../assets/Uli fotos/IMG_0712 - feed.jpg';
import ClinicaFeed9 from '../../assets/Uli fotos/IMG_0752-HDR - feed.jpg';
import ClinicaFeed10 from '../../assets/Uli fotos/IMG_0773 - feed.jpg';
import ClinicaFeed11 from '../../assets/Uli fotos/IMG_0790 - feed.jpg';
import ClinicaFeed12 from '../../assets/Uli fotos/IMG_0786 - feed.jpg';

import ImagemAplicandoTratamento1 from '../../assets/Uli fotos/UliNew/_MG_1737-min.jpg';
import ImagemAplicandoTratamento2 from '../../assets/Uli fotos/UliNew/_MG_1747-min.jpg';
import ImagemAplicandoTratamento3 from '../../assets/Uli fotos/UliNew/_MG_1766-min.jpg';
import ImagemAplicandoTratamento4 from '../../assets/Uli fotos/UliNew/_MG_1811-min.jpg';
import ImagemAplicandoTratamento5 from '../../assets/Uli fotos/UliNew/_MG_1815-min.jpg';
import ImagemAplicandoTratamento6 from '../../assets/Uli fotos/UliNew/_MG_1871-min.jpg';
import ImagemAplicandoTratamento7 from '../../assets/Uli fotos/UliNew/_MG_1873-min.jpg';


const AboutSection = () => {
    return(
        <div className={styles["AboutContainer"]}>
            <div className={styles["AboutContent"]}>
                <h3 className={styles["MainAboutClinic"]}>Um espaço dedicado à sua beleza e bem-estar</h3>
                <span className={styles["TextAboutClinic"]}>
                    A UliClinic oferece uma variedade de tratamentos para melhorar a aparência e a saúde da pele e corpo. A nossa equipe multidisciplinar de profissionais qualificados está comprometida em proporcionar aos nossos clientes os melhores resultados possíveis.
                </span>
                <span className={styles["TextAboutClinic"]}>
                    Nossas especialidades são procedimentos faciais, corporais e pós-operatório. Dispomos de tecnologias de ponta e equipe multidisciplinar para oferecer tratamentos de alta qualidade em cada área. Nossos procedimentos faciais, corporais e pós-operatórios incluem tratamentos avançados de rejuvenescimento, como radiofrequência, microagulhamento e peelings químicos, além de limpezas profundas e terapias específicas para cada tipo de pele.
                </span>
            </div>
        </div>
    )
}

const Clinica = () => {

    const images = [
        ClinicaFeed1, ClinicaFeed2, 
        ClinicaFeed3, ClinicaFeed4, 
        ClinicaFeed5 ,ClinicaFeed6 ,
        ClinicaFeed7 ,ClinicaFeed8, 
        ClinicaFeed9, ClinicaFeed10, 
        ImagemAplicandoTratamento7,
        ClinicaFeed11, ClinicaFeed12,
        ImagemAplicandoTratamento3, ImagemAplicandoTratamento4,
        ImagemAplicandoTratamento5, ImagemAplicandoTratamento6,
        ImagemAplicandoTratamento1, ImagemAplicandoTratamento2,
    ];

    return(
        <>
            <NavBar/>
            <SectionTitle title={"Clínica"}/>
            <MainBanner bkgdCover={"cover"} wppButton={false} imageUrl={BannerScreenPng} theme="dark" height='33rem'>
                <></>
            </MainBanner>
            <AboutSection />
            <SectionTitle title={"Galeria de Fotos"}/>
            <div className={styles["CarouselContainer"]}>
                <Carousel images={images.sort(() => Math.random() - 0.5)} />
            </div>
            <MapSection/>
            <Footer/>
        </>
    )
}

export default Clinica