import styles from './Login.module.scss';
import { Input } from '../../components';
import { AuthContext } from '../../Contexts';
import Form from '../../components/Form/Form';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ArrowRight, DangerIcon, EyeIcon, MainLogo } from '../../assets';


interface LoginObject{
    email : string,
    password: string,
}

const LoginView = () => {
    
    const navigate = useNavigate();
    const [ showPass, setShowPass ] = useState<boolean>(false);
    const { login, authenticated, addToast } = useContext( AuthContext );
    const [ validatorSend, setValidatorSend ] = useState<boolean>(false);
    const [ errorInSendRequest, setErrorRequest ] = useState<boolean>(false);
    const [ credentials, setCredentials ] = useState<LoginObject>({password : '', email : ''});

    const handleChangeValue = useCallback( () => {
        const MailPattern : RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
        const emailIsValid = credentials.email.length > 5 && MailPattern.test( credentials.email );
        const passwordIsValid = credentials.password.length > 3
        
        if( emailIsValid && passwordIsValid ){
            setValidatorSend(true)
            return
        }
        
        setValidatorSend(false)
        
    }, [ credentials.email, credentials.password ])
    
    
    useEffect( () => {
        
        if( authenticated ){
            navigate('..');
            addToast({message: "Você ja está logado !", type: "warning"});
        }
        handleChangeValue()

    }, [authenticated, navigate, handleChangeValue, addToast])
    
    const handleLogin = async ( response : any )  => {

        const data = {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
        }
        login(data);
        addToast({message: "Logado com sucesso !", type: "success"});
        
        navigate('/',  { replace: true });
        window.scroll(0,0);
        
    }

    const onSubmitError = ( errorData : any ) => {
        // se chamou essa função trocar o state de error e exibir um aviso
        addToast({message : "Email ou senha inconsistente !", type : "error"});
        setErrorRequest(true);
        return
    }
    
    return(
        <Form url='/auth' additionalMethods={handleLogin} onSubmitError={onSubmitError}>
            <div className={styles["Container"]}>
                <div className={styles["NavBarLogin"]}>
                    <a href={'/'} onClick={() => window.scroll(0,0)}>
                        <MainLogo className={styles['MainLogo']} />
                    </a>
                </div>
                <div className={styles["ContentContainer"]}>
                    <div className={styles["Content"]}>
                        <div className={styles["ContentHeader"]}>
                            <a href={'/'} onClick={() => window.scroll(0,0)} className={styles["VoltarSite"]}>
                                <ArrowRight />
                                <span>Voltar ao site</span>
                            </a>
                            <div className={styles["MainHeader"]}>Log in</div>
                            <div className={styles["SubtitleHeader"]}>Continue sua jornada com a UliClinic</div>
                        </div>
                        <div className={styles["InputsSection"]}>
                            { errorInSendRequest && (
                                <div className={styles['ErrorMessage']}>
                                    <div className={styles["ErrorSvg"]}>
                                        <DangerIcon/>
                                    </div>
                                    <span>Não foi possível efetuar o login, verifique o email e senha e tente novamente</span>
                                </div>
                            ) }
                            <div className={styles['InputContainer']}>
                                <div>{"Email"}</div>
                                <Input 
                                    fieldName='email'
                                    StyleObject={styles}
                                    placeholder={"Insira seu Email"}
                                    value_input={ credentials?.email }
                                    classname='InputElementIcon'type={"email"}
                                    onChangeValue={(ev: any) => setCredentials( {...credentials, email : ev.target.value} ) }/>
                            </div>
                            <div className={styles['InputContainer']}>
                                <div>{"Senha"}</div>
                                <Input 
                                    fieldName='password'
                                    StyleObject={styles}
                                    classname='InputElementIcon'
                                    placeholder={"Insira sua Senha"}
                                    value_input={ credentials?.password }
                                    type={ showPass ? "text" : "password" }
                                    onChangeValue={(ev: any) => setCredentials( {...credentials, password : ev.target.value } ) }
                                    IconRigth={ <EyeIcon className={styles["EyeIcon"]} onClick={() => setShowPass(prev => !prev)}/> } />
                            </div>
                        </div>
                        <button disabled={!validatorSend} type={"submit"} className={styles["SendButton"]}>Entrar</button>
                    </div>
                </div>
            </div>
        </Form>
            
    )
}

export default LoginView;