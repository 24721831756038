import styles from './MapSection.module.scss'
import { PinLocal } from '../../assets'


const MapSection = () => {
    return(
        <div className={styles['Container']}>
            <div className={styles['Content']}>
                <div className={styles['Info']}>
                    <div className={styles['InfoTitle']}>Contato</div>
                    <div className={styles['InfoData']}>
                        <div className={styles['InfoDataRow']}>
                            <span className={styles['RowTitle']}>Telefone</span>
                            <span className={styles['RowText']}>+55 (85) 99179-1916</span>
                        </div>
                        <div className={styles['InfoDataRow']}>
                            <span className={styles['RowTitle']}>Redes Sociais</span>
                            <span className={styles['RowText']}>@Uliclinic</span>
                        </div>
                        <div className={styles['InfoDataRow']}>
                            <span className={styles['RowTitle']}>Endereço</span>
                            <span className={styles['RowText']}>Rua Marcos Macêdo, 1333 - sala 1819 - Aldeota, Fortaleza - CE, 60150-190</span>
                        </div>
                    </div>    
                </div>
                
                <div className={styles['Map']}>
                    <a href="https://www.google.com.br/maps/dir//Rua+Marcos+Mac%C3%AAdo,+1333+-+Meireles,+Fortaleza+-+CE,+60150-190/@-3.7361989,-38.4928313,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x7c7487dfffda897:0x6a9df6b46f5d7799!2m2!1d-38.4902564!2d-3.7362043?entry=ttu" target='blank'> <span>DEFINIR ROTA</span> <PinLocal/></a>
                </div>
            </div>
        </div>
    )
}

export default MapSection