import FacialSection from '../../assets/TratamentosImages/Facial/LimpezaDePele.jpg';
import CorporalSection from '../../assets/TratamentosImages/Corporal/Massagem.jpg';
import PosOperatorioSection from '../../assets/TratamentosImages/PosOperatorio/PosOperatorioSection.jpg';

import { SectionTitle, Pagination, TratamentoItem, NavBar, Footer } from "../../components"


const Tratamentos = () => {
    const elements = 9;

    const tratamentoFacial = "Realce sua beleza natural com nossos tratamentos faciais rejuvenescedores. Descubra uma jornada de cuidados personalizados para a pele, restaurando o brilho e a vitalidade. Deixe-nos cuidar de você e revelar sua melhor versão."
    const tratamentoCorporal = "Aprimore sua harmonia corporal com nossos tratamentos revitalizantes. Explore uma experiência de cuidados personalizados para o corpo, revitalizando a vitalidade e o equilíbrio. Confie em nós para cuidar de você, revelando a sua expressão mais radiante."
    const tratamentoPosOperatorio = "Recupere-se plenamente com nossos tratamentos especializados para a fase pós-operatória. Explore uma jornada de cuidados personalizados, impulsionando sua recuperação e promovendo o bem-estar. Confie em nós para guiá-lo durante o processo de reabilitação, revelando sua melhor versão após a intervenção."
    
    const tratamentosItems = [
        <TratamentoItem ideiaImage={"secção de tratamentos faciais"} title={"Faciais"} linkImage={FacialSection} description={tratamentoFacial} routerSegmented={"/tratamentos/facial"}/>, 
        <TratamentoItem ideiaImage={"secção de tratamentos corporais"} title={"Corporais"} linkImage={CorporalSection} description={tratamentoCorporal} routerSegmented={"/tratamentos/corporal"}/>, 
        <TratamentoItem ideiaImage={"secção de tratamentos pós-operatórios"} title={"Pós-Operatórios"} linkImage={PosOperatorioSection} description={tratamentoPosOperatorio} routerSegmented={"/tratamentos/posOperatorio"}/>,
    ];
    
    return(
        <>
            <NavBar/>
            <SectionTitle title={"Tratamentos"}/>
            <div style={{margin: "25px 0"}}>
                <Pagination items={tratamentosItems} elementsPerPage={elements}/>
            </div>
            <Footer/>
        </>
    )
}

export default Tratamentos