import { PinLocal } from "../../assets"
import { Footer, NavBar, SectionTitle } from "../../components"
import styles from './OndeEstamos.module.scss'


const OndeEstamos = () => {
    return(
        <>
            <NavBar/>
            <SectionTitle title={"Onde Estamos"}/>
            <div className={styles['Map']}>
                <a href="https://www.google.com.br/maps/dir//Rua+Marcos+Mac%C3%AAdo,+1333+-+Meireles,+Fortaleza+-+CE,+60150-190/@-3.7361989,-38.4928313,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x7c7487dfffda897:0x6a9df6b46f5d7799!2m2!1d-38.4902564!2d-3.7362043?entry=ttu" target='blank'><span>DEFINIR ROTA</span><PinLocal/></a>
            </div>
            <Footer/>
        </>
    )
}

export default OndeEstamos