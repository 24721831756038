import React, { useState } from 'react'
import { ArrowRight } from '../../assets'
import styles from './Pagination.module.scss'


type PaginationProps = {
    items: Array<React.JSX.Element>,
    elementsPerPage: number,
}

const Pagination = (props: PaginationProps) => {
    const [count, setCount] = useState<number>(props.elementsPerPage);
    const items = props.items.filter((item: React.JSX.Element, index: number) => index < count);

    return(
        <div className={styles['PaginationContainer']}>
            { items.map((e, index) => 
                <div key={index} style={{marginBottom: "50px", display : "flex", alignContent: "center", justifyContent: "center"}}>
                    {e}
                </div>
            )}

            { count < props.items.length &&
                <div className={styles['ButtonContainer']}>
                    <button className={styles['Button']} onClick={() => { setCount((prevState: number) => prevState + props.elementsPerPage)}}>Veja Mais<ArrowRight style={{marginLeft: "4px"}}/></button>
                </div>
            }
        </div>    
    )
}

export default Pagination