import { Link } from "react-router-dom";
import styles from "./AlternativeInfo.module.scss";
import { ArrowRight } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";


interface BoxInfoContainer{
    titleInfo : string,
    description : string,
}

interface AlternativeInfoProps{
    Titulo : string,
    RotaButton : string,
    ImagemPath: string,
    Descricoes: BoxInfoContainer[]
}

const BlockInfo = ( {description, titleInfo} : BoxInfoContainer ) => {
    return(
        <div className={styles["DescriptionContainer"]}>
            <span className={styles["TitleInfo"]}>{titleInfo}</span>
            <span className={styles["DescriptionInfo"]}>{description}</span>
        </div>
    )
}

const AlternativeInfo = ( {Descricoes, RotaButton, ImagemPath, Titulo} : AlternativeInfoProps ) => {
    return(
        <div className={styles["Container"]}>
            <div className={styles["Header"]}>
                <span className={styles["TitleHeader"]}>{Titulo}</span>
                <span className={styles["SubTitleHeader"]}>Conheça os principais procedimentos que realizo</span>
            </div>
            <div className={styles["Content"]}>
                <div className={styles["FirstColumn"]}>
                    <BlockInfo description={Descricoes[0].description} titleInfo={Descricoes[0].titleInfo} />
                    <BlockInfo description={Descricoes[1].description} titleInfo={Descricoes[1].titleInfo} />
                </div>
                <LazyLoadImage 
                    effect='blur'
                    loading={"lazy"} 
                    src={ImagemPath} className={styles["Picture"]} alt={"imagem da profisional"}/>
                <div className={styles["LastColumn"]}>
                    <BlockInfo description={Descricoes[2].description} titleInfo={Descricoes[2].titleInfo} />
                    <BlockInfo description={Descricoes[3].description} titleInfo={Descricoes[3].titleInfo} />
                </div>
            </div>
            <Link to={RotaButton} className={styles["Button"]} onClick={() => window.scroll(0,0)}>Ver Curriculo Completo<ArrowRight/></Link>
        </div>
    )
}

export default AlternativeInfo;