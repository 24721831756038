import luanda from "../../../assets/Uli fotos/_MG_1675.jpg";
import { AlternativeInfo, Footer, NavBar } from "../../../components";


const LuandaAboutMinimal = () => {

    const Descricoes = [
        {   titleInfo : "Tratamento de estrias",
            description : "Sou licenciada na técnica Striort que é um tratamento ortomolecular para o combate a todos os tipos de estrias, ajudo minhas pacientes a alcançar os melhores resultados sem causar dor."
    }, {
        titleInfo : "Pós-operatório",
        description : "Sou licenciada na técnica fibrose zero que é considerada padrão ouro nos atendimentos de pós-operatório de cirurgias plásticas.",
    }, {
        titleInfo : "Detox Uli",
        description : "Detox Corporal é um procedimento estético que auxilia na eliminação das toxinas existentes no nosso organismo, com protocolo exclusivo desenvolvido por nós da Uli Clinic, onde você pode perder até 1kg de retenção hídrica."
    }, {
        titleInfo : "Limpeza de pele",
        description : "Com especialização em procedimentos faciais, a limpeza de pele é sem dúvida a base para os tratamentos e é o procedimento mais realizado."
    }]

    return(
        <>
            <NavBar/>
            <AlternativeInfo Descricoes={Descricoes} RotaButton={"/sobre/Luanda/Complete"} ImagemPath={luanda} Titulo={"Como cuido de você?"}/>    
            <Footer/>
        </>
    )
}

export default LuandaAboutMinimal;