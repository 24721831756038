import { Api } from '../../services';
import styles from './Carousel.module.scss';
import { AuthContext } from '../../Contexts';
import AliceCarousel from 'react-alice-carousel';
import { CloseIcon, IconPen } from '../../assets';
import 'react-alice-carousel/lib/alice-carousel.css';

import { useContext, useLayoutEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ContextModalAddOff } from '../../Contexts/OffContexts/ModalAddOff';
import { ContextModalDeleteOff } from '../../Contexts/OffContexts/ModalDeleteOff';


export interface imageObjectResponse{
    "id": number,
    "link": string,
    "name": string,
    "mimetype": string,
}

interface CarouselProps{
    images? : string[]
}

const Carousel = ( props : CarouselProps ) => {
    
    const { authenticated } = useContext( AuthContext );
    const ModalAddOffItem = useContext(ContextModalAddOff);
    const ModalDeleteOffItem = useContext(ContextModalDeleteOff);
    const [ images_render, setImagesRender ] = useState<unknown[] | undefined>(undefined);
    
    const responsive = {
        0: { items: 1 },
        670: { items: 2 },
        770: { items: 3 },
        1100: { items: 4 },
    };

    const items = props.images?.map( (e: string, index: number) => { return(
        <div className={`item ${styles["StyleItem"]}`} key={index++}>
            <img src={e}  draggable={false} alt="Imagem de Promoção" className={styles["imagesRenderizadas"]}/>
        </div>
    )})

    useLayoutEffect( () => {
        if( props.images === undefined ){
            Api.get( `/image/all` ).then( (response) => {
                
                setImagesRender(response.data.images as imageObjectResponse[]);
            }).catch( (error) => {
                
                setImagesRender([]);
            } )
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [ props.images, ModalDeleteOffItem, ModalAddOffItem ] )


    const handleItemsByApi = () : JSX.Element[] => {

        return ( images_render as imageObjectResponse[])?.map( ( image_object, index ) => { return(
            <div className={`item ${styles["StyleItem"]}`} key={index}>
                <a href={`http://${image_object.link}`} target='_blank' rel="noreferrer">
                    <LazyLoadImage 
                        loading={"lazy"}
                        effect='blur'
                        className={styles["imageCarousel"]}
                        draggable={false} alt={"Imagem de ofertas"}
                        src={`${Api.defaults.baseURL}/image/get/${image_object.id}`} />
                </a>
            {   authenticated && (
                <div className={styles["ButtonsContainer"]}>
                    <button className={styles["ButtonEdit"]} 
                            onClick={ () => ModalAddOffItem?.setRenderModal({id : image_object.id, renderModal : true})}>
                        <IconPen />
                        <span>Editar</span>
                    </button>
                    <button className={styles["ButtonRemove"]} 
                            onClick={ () => ModalDeleteOffItem?.setRenderModal({id : image_object.id, renderModal : true})}>
                        <CloseIcon />
                        <span>Excluir</span>
                    </button>
                </div>
            )}
            </div>
        )})
    }

    return(
        <>  { images_render?.length === 0 ? (

            <div className={styles["NaoTemOff"]}>
                <span>No momento, não há ofertas.</span>
            </div> ) : (
                <div style={{marginTop: "40px", marginBottom: "20px"}}>
                    <AliceCarousel
                        infinite
                        autoPlay={false}
                        disableButtonsControls
                        responsive={responsive}
                        autoPlayInterval={2500}
                        controlsStrategy="responsive"
                        items={ props.images !== undefined ? items as any[] : handleItemsByApi() }
                        renderDotsItem={(e) => e.isActive ? <div className={styles["Dots"]}></div> : <div className={styles["DotsInactive"]}></div>} />
                </div>
            )}
        </>
    )
}

export default Carousel