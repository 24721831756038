import RinoplastiaImage from '../../../assets/TratamentosImages/PosOperatorio/Rinoplastia.jpg';
import PapadaImage from '../../../assets/TratamentosImages/PosOperatorio/Papada.jpg';
import PosPartoImage from '../../../assets/TratamentosImages/PosOperatorio/PostPartum.jpg';
import LipoAspiracaoImage from '../../../assets/TratamentosImages/PosOperatorio/LipoAspiracao.jpg';
import AbdominoplatiaImage from '../../../assets/TratamentosImages/PosOperatorio/Abdominoplastia.jpg';
import PostumFaceImage from '../../../assets/TratamentosImages/PosOperatorio/DeepPlane.jpg';
import PostumMamaImage from '../../../assets/TratamentosImages/PosOperatorio/PosDeMama.jpg';
import PostRenivionImage from '../../../assets/TratamentosImages/PosOperatorio/Renuvion.jpg';


import { SectionTitle, ServiceItem, Pagination, NavBar, Footer } from "../../../components"


const Tratamentos = () => {
    const elements = 9;
    
    const TratamentosItems = [
        <ServiceItem ideia={"Foto do serviço de Rinoplastia"}       title={"Rinoplastia"} imageLink={RinoplastiaImage}     description={"Explore a jornada após a rinoplastia, onde nossos cuidados personalizados destacam a beleza única do seu rosto. Técnicas precisas são aplicadas para uma transição suave durante a recuperação, integrando-se naturalmente à sua expressão singular."}/>, 
        <ServiceItem ideia={"Foto do serviço de redução de papada"} title={"Papada"}      imageLink={PapadaImage}          description={"Adentre a fase pós-tratamento de redução de papada, onde cuidados exclusivos realçam a singularidade do seu rosto. Com precisão, buscamos uma transição suave durante a recuperação, integrando-a de maneira fluida à sua beleza natural."} />, 
        <ServiceItem ideia={"Foto do serviço de Pós-parto"}         title={"Pós-parto"}   imageLink={PosPartoImage}        description={"Embarque na jornada pós-parto, onde cada cuidado é uma escultura delicada para revitalizar a vitalidade após a gestação. Abordagens específicas são aplicadas para promover uma recuperação equilibrada, visando o bem-estar e destacando a beleza intrínseca."} />, 
        <ServiceItem ideia={"Foto do serviço de lipoaspiração"}     title={"Lipoaspiração"}   imageLink={LipoAspiracaoImage} description={"Explore a transformação pós-lipoaspiração, onde moldamos a forma corporal de maneira única. Nossos especialistas em fisioterapia aplicam técnicas personalizadas para otimizar a recuperação, garantindo uma transição suave e respeitando a individualidade da sua silhueta."} />, 
        <ServiceItem ideia={"Foto do serviço de abdominoplatia"}    title={"Abdominoplastia"} imageLink={AbdominoplatiaImage} description={"Descubra a fase pós-abdominoplastia em nosso espaço dedicado. Nossos profissionais de fisioterapia aplicam abordagens específicas, facilitando a remoção do excesso de pele e gordura para uma região abdominal mais firme e esculpida, integrando-se de forma orgânica ao seu corpo."} />, 
        <ServiceItem ideia={"Foto do serviço de pós de face"}       title={"Pós de face - ritidoplastia e deep plane"}          imageLink={PostumFaceImage} description={"Desfrute da renovação facial com nossa abordagem especializada em Ritidoplastia e Deep Plane. Nossos cirurgiões parceiros experientes neste tratamento proporcionam resultados de rejuvenecimento natural e duradouro. Descrubra a harmonia facial aprimorada, revelando uma expressão renovada e elegante."} />, 
        <ServiceItem ideia={"Foto do serviço de pós de mama"}       title={"Pós de mama - mastopexia e mamoplastia de aumento"} imageLink={PostumMamaImage} description={"Após a intervenção mamária, explore a fase de cuidados onde cada etapa é dedicada a realçar a beleza única. Profissionais aplicam técnicas delicadas para destacar não apenas a forma, mas também a narrativa visual da sua essência durante o processo de recuperação."} />, 
        <ServiceItem ideia={"Foto do serviço de pós de renuvion"}   title={"Pós de Renuvion, Morpheus e Bodytite"}              imageLink={PostRenivionImage} description={"Explore a renovação pós-Renuvion, onde oferecemos cuidados especializados para destacar a transformação alcançada. Profissionais aplicam técnicas específicas para otimizar a recuperação, garantindo uma integração suave com os resultados de rejuvenescimento."} />, 
    ]

    return(
        <>
            <NavBar/>
            <SectionTitle title={"Tratamentos pós-operatórios"} stylesProp={{"fontSize" : "2rem"}}/>
            <div style={{margin: "25px 0"}}>
                <Pagination items={TratamentosItems} elementsPerPage={elements}/>
            </div>
            <Footer/>
        </>
    )
}

export default Tratamentos