import { Api } from '../../../services';
import styles from './modal.module.scss';
import Input from '../../Form/Input/Input';
import { AuthContext } from '../../../Contexts';
import frame from '../../../assets/LogosPngs/Frame.png';
import { CloseIcon, CloudUp, IconPen } from '../../../assets';
import { imageObjectResponse } from '../../Carousel/Carousel';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ContextModalAddOff } from '../../../Contexts/OffContexts/ModalAddOff';

type Modal = {
    id?: number | undefined;
    renderModal?: boolean | undefined;
    type?: "add" | "edit" | undefined;
} | undefined

interface ImageUpload{
    values: any, 
    isFirst: boolean,
    ModalItem : Modal,
}

const ImageUploadedShow = ( props : ImageUpload) => {
    
    const imageUrl =
      props.ModalItem?.id !== undefined && props.isFirst
        ? `${Api.defaults.baseURL}/image/get/${props.ModalItem?.id}`
        : URL.createObjectURL(props.values?.file) || "";

    return (
      <img
        key={props.ModalItem?.id || "image_example"}
        src={imageUrl}
        className={styles["ImageContainer"]}
        alt="Ilustração da imagem"
      />
    );
};

const ModalAdd = () => {
    
    const { addToast } = useContext(AuthContext);
    const ModalItemAddOff = useContext(ContextModalAddOff);
    const [ isFirst, setIsFirst ] = useState<boolean>(true);
    const [ sendForm, setSendForm ] = useState<boolean>(true);
    const [ values, setValues ] = useState<{link : string, file : any} | null>({
        link : "" , file : null
    });
    
    useEffect( () => {

        if ( ModalItemAddOff?.id !== undefined ){
            Api.get(`${Api.defaults.baseURL}/image/view/${ModalItemAddOff.id}`)
            .then( (response) => {
                
                setSendForm( false )
                putValueToEdit(response.data.image as imageObjectResponse);
            }).catch( (error) => {
                addToast({message : "Não foi possível carregar as informações !", type: "error"});
            } ) 
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [ ModalItemAddOff ])

    const handleChangeFile = (ev : React.SyntheticEvent) => {
        setIsFirst(false);
        setValues( (prev: any) => {return({...prev, file : (ev.target as any).files[0] })} )
    }

    const handleChangeValue = ( ev : SyntheticEvent, key : string ) => {
        setValues( (prev:any) => {return({...prev, [key] : (ev.target as HTMLInputElement).value})} );

        if( values === null || values.link === undefined  ){
            return
        }
        if( values.link.length > 5 ){
            setSendForm( false )
        }
    }

    const putValueToEdit = async ( object_image : imageObjectResponse) => {
                
        const res : any = await Api.get(`/image/get/${object_image.id}`);
        setValues( {link : object_image.link, file : res.data } );
    }

    const handleCloseModal = ( ev : SyntheticEvent ) => {
        URL.revokeObjectURL(values?.file);
        setIsFirst(true);
        setValues(null);
        ModalItemAddOff?.setRenderModal({id : undefined, renderModal : false, type : undefined});
    }

    const handleSubmit = ( ev : SyntheticEvent ) => {
        ev.preventDefault();

        if ( values === null || values.file === undefined || values.file === null) {
            addToast({message: "Preecha todos os campos e escolha uma imagem !", type : 'warning'})
            return
        }
        
        let data = new FormData();
        data.append('link', values.link);
        data.append('image', values.file);

        let UrlToApi = null;

        if( ModalItemAddOff?.id !== undefined ){

            let data = {
                "link" : values.link,
            }

            UrlToApi = `${Api.defaults.baseURL}/image/edit/${ModalItemAddOff.id}`

            Api.put( UrlToApi, data, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': `application/json`,
                }
            })
            .then((response) => {

                handleCloseModal(ev)
                addToast({message: "Promoção Editada com sucesso !", type: "success"});
            }).catch((error) => {

                addToast({message: "Não foi possível editar a promoção !", type: "error"});
            });

            return
        }

        UrlToApi = `${Api.defaults.baseURL}/image/add`
        
        Api.post(UrlToApi, data, {
            headers: {
                'accept': 'application/json',
                'Content-Type': `multipart/form-data`,
            }
        })
        .then( (response) => {

            handleCloseModal(ev);
            addToast({message: "Promoção Adicionada !", type: "success"});

        }).catch( (error) => {

            addToast({message: "Promoção não Adicionada !", type: "error"});
        });
    }

    return (

        <>
        {   ModalItemAddOff?.renderModal && (
            <form onSubmit={handleSubmit}> 
                <div className={styles["ModalContainer"]}>
                    <div className={styles["DarkSide"]} onClick={handleCloseModal}></div>
                    <div className={styles["ModalContentContainer"]}>

                        <div className={styles["CloseButton"]} onClick={handleCloseModal}>
                            <CloseIcon />
                        </div>

                        <div className={styles["ModalHeader"]}>
                            <span>Promoções</span>
                        </div>
                        <div className={styles['ModalContent']}>
                            <div className={styles["ModalContentFirstColumn"]}>
                                <span className={styles['MainHeader']}>Ilustração</span>
                                <span className={styles["Header"]}>Uma Ilustração de como será exibido a promoção no sistema</span>
                                {   ( values !== null && values.file !== undefined && values.file !== null ) ? (
                                    <ImageUploadedShow 
                                        values={values}
                                        isFirst={isFirst}
                                        key={"ImagemCarregada"}
                                        ModalItem={ModalItemAddOff}
                                    />) : (
                                        <div className={styles["ImageContainer"]} style={{backgroundImage: `url(${frame})`}} >
                                            <IconPen />
                                        </div>
                                    )
                                }
                            </div>
                            <div className={styles["ModalContentSecondColumn"]}>
                                <span className={styles["MainHeader"]}>Informações</span>
                                <div className={styles["InputSection"]}>
                                    <div className={styles["InputElement"]}>
                                        <span className={styles["LabelInput"]}>Link para redirecionamento</span>
                                        <Input 
                                            type='text'
                                            fieldName='link'
                                            classname="Input"
                                            StyleObject={styles}
                                            key={"input_image_link"}
                                            value_input={values?.link || ""}
                                            placeholder={"Insira o link de redirecionamento"}
                                            onChangeValue={(ev: React.SyntheticEvent) => handleChangeValue(ev, "link")} />
                                    </div>
                                    <div className={styles["InputElementDrop"]}>
                                        {   ModalItemAddOff.id === undefined && (
                                            <>
                                              <span className={styles["LabelInput"]} >Selecione sua imagem</span>
                                              <label htmlFor={"image"} className={styles["SelectImageButton"]}>
                                                  <CloudUp />
                                                  <span className={styles["LabelSendInput"]} >Selecionar Imagem</span>
                                              </label>
                                              <input name='image' value={""} id={"image"} type="file" className={styles["SelectImageButton"]} onChange={ handleChangeFile }/>
                                            </>
                                          )  
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    <button disabled={sendForm} className={styles["SubmitButton"]}>
                        { ModalItemAddOff.id !== undefined ? "Editar" : "Adicionar"}
                    </button>
                </div>
            </div>
        </form>
        )}
    </>
    )
}

export default ModalAdd;