import { 
    PinLocal,
    ExitIcon,
    Instagram,
    CloseIcon,
    MenuCompact,
    IconExitDoor,
    PlusTelephone,
} from "../../assets";

import { Link} from "react-router-dom";
import styles from "./navbar.module.scss";
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts";
import { CardNavBarProps, DropDownLink, MenuItemInterface, DropDownAction, closeFunction } from "./types";
import MainLogo from "../../assets/LogosPngs/MainLogo.png";


const CardNavBarItems = ({title ,link, svgIcons}: CardNavBarProps) => {
    return(
        <Link to={link} target='_blank' rel='noreferrer' className={styles['CardLinkNavBarContainer']} onClick={() => window.scroll(0,0)}>
            <div className={styles['CardLinkNavBar']}>
                {svgIcons} <p>{title}</p>
            </div>
        </Link>
    )
}

const CardNavBarLogout = () => {
    
    const { authenticated, logout} = useContext( AuthContext );

    return(
        <>
        { authenticated && (
            <div className={styles["LogoutButton"]} onClick={() => logout()}>
                <ExitIcon/>
                <span>Sair</span>
            </div>
        )}
        </>
    )
}

const MenuItemNavBar = ({link , title, closeModalFunc} : MenuItemInterface & closeFunction ) => {
    const [ divSelected, setDivSelected ] = useState<boolean>(false);

    return(
        <Link to={link || ""} onClick={() => window.scroll(0,0)}>
            <div onClick={ () => {setDivSelected(!divSelected);closeModalFunc()} } className={!divSelected ? styles["MenuItem"] : styles["MenuItemClicked"]} >
                {title}
            </div>
        </Link>
    )
}

const DropDownItens = ( props : DropDownLink ) => {
    return(
        <Link to={props.route} className={styles["DropDonwAboutItens"]} onClick={() => window.scroll(0,0)}>{props.title}</Link>
    )
}

const NavBar = () => {
  
    const { authenticated, logout} = useContext(AuthContext);
    const [ bttnMenuPressed, setSttsMenu ] = useState<boolean>( true );
    const [ dropDownMenu, setDropDownMenu ] = useState<boolean>( false );
    const [ dropDownTreatsMenu, setDropDownTreatsMenu ] = useState<boolean>( false );
    
    const handleCloseMenu = () => {
        setSttsMenu(true);
        setDropDownMenu(false);
    }

    const exitFunc = () => {
        logout();
        handleCloseMenu();        
    }

    const dropDownItens : DropDownLink[] = [
        {   
            title: "Lia",
            route : "/sobre/Lia",
        }, 
        {
            title: "Luanda",
            route : "/sobre/Luanda",
        }, 
        {
            title: "Marcia Izadora",
            route : "/sobre/Marcia",
        }
    ]

    const dropDownItensActions: DropDownAction = {
        showDropDown: dropDownMenu,
        setshowDropDown: setDropDownMenu
    }

    const dropDownTreats: DropDownLink[] = [
        {
            title: "Facial",
            route: "/tratamentos/facial",
        },
        {
            title: "Corporal",
            route: "/tratamentos/corporal",
        },
        {
            title: "Pós-Operatório",
            route: "/tratamentos/posOperatorio",
        }
    ]

    const dropDownTreatsActions: DropDownAction = {
        showDropDown: dropDownTreatsMenu,
        setshowDropDown: setDropDownTreatsMenu
    }

    const itensMenu: MenuItemInterface[] = [
        { title : "Home" , link : "/" },
        { title : "Clinica" , link : "/clinica" },
        { title : "EquipeUli", link: "/equipeuli", dropDownDivs: dropDownItens, dropDownActions: dropDownItensActions },
        { title : "Tratamentos" , link : "/tratamentos", dropDownDivs: dropDownTreats, dropDownActions: dropDownTreatsActions },
        { title : "Onde Estamos" , link : "/onde-estamos" },
    ]
    
    return(
        <header className={styles["NavBarContainerGlobal"]}>
            <div className={styles["NavBarContainer"]}>
                <div className={styles["NavBarUpperContainer"]}>
                    <div className={styles["NavBarUpper"]}>
                        <div className={styles["LocalizacaoNavBar"]}>
                            <PinLocal className={styles["pinLocalSvg"]}/>
                            <a href="https://www.google.com.br/maps/dir//Rua+Marcos+Mac%C3%AAdo,+1333+-+Meireles,+Fortaleza+-+CE,+60150-190/@-3.7361989,-38.4928313,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x7c7487dfffda897:0x6a9df6b46f5d7799!2m2!1d-38.4902564!2d-3.7362043?entry=ttu" target="_blank" rel="noreferrer" onClick={() => window.scroll(0,0)}>
                                <p>Rua Marcos Macêdo, 1333 - sala 1819 - Aldeota, Fortaleza - CE, 60150-190</p>
                            </a>
                        </div>
                        <div className={styles["LinksNavBarUpperContainer"]}>
                            <CardNavBarLogout />
                            <CardNavBarItems title="UliClinic" link="https://www.instagram.com/uliclinic/" svgIcons={<Instagram />}/>
                            <CardNavBarItems title="Agendamento" link="https://api.whatsapp.com/send?phone=5585991791916&text=Oi,%20eu%20queria%20marcar%20uma%20avalia%C3%A7%C3%A3o%20com%20voc%C3%AAs%20%F0%9F%A4%A9" svgIcons={<PlusTelephone/>}/>
                        </div>
                    </div>
                </div>
                <div className={styles["NavBarDownContainer"]}>
                    <div className={styles["NavBarDown"]}>
                        <Link onClick={handleCloseMenu}  to={"/"} className={styles["MainLogo"]}>
                            <img src={MainLogo} alt="LogoTipo Da Empresa"/>
                        </Link>
                        <div className={styles["NavigatorLinksContainer"]}>
                            <ul className={styles["NavigatorLinksItens"]}>
                                { itensMenu.map( (item, index) => {return(
                                    <li className={styles["NavigatorLinks"]} key={index}>
                                    { item.dropDownDivs === undefined ? <Link to={item.link || ""} onClick={() => {window.scroll(0,0); handleCloseMenu()}}>{item.title}</Link> : 
                                    <>
                                        <button onMouseLeave={() => item.dropDownActions?.setshowDropDown(false)} onMouseEnter={() => item.dropDownActions?.setshowDropDown(true)}>{item.title}</button>
                                        { item.dropDownActions?.showDropDown && (
                                            <div onMouseEnter={() => item.dropDownActions?.setshowDropDown(true)} onMouseLeave={() => item.dropDownActions?.setshowDropDown(false)} className={styles["DropDownContainer"]}>
                                                { item.dropDownDivs.map( (object : DropDownLink, index_inter) => { return(
                                                    <DropDownItens key={index_inter} route={object.route} title={object.title} />
                                                )})}
                                            </div>
                                        )}
                                    </>}
                                    </li>
                                )} )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["NavBarMobContainer"]}>
                <Link onClick={handleCloseMenu} to={"/"}>
                    <img src={MainLogo} alt="LogoTipo Da Empresa" className={styles["MainLogo"]}/>
                </Link>
                <button className={styles["MenuCompact"]} onClick={ () => setSttsMenu(!bttnMenuPressed) }>
                    { !bttnMenuPressed ? <CloseIcon className={styles["MenuCompact"]}/> : <MenuCompact className={styles["MenuCompact"]}/> }
                </button>
            </div>
            { !bttnMenuPressed && (
                <div className={styles["MenuContainer"]}>
                    {itensMenu.map( (item, index) => <MenuItemNavBar closeModalFunc={handleCloseMenu} title={item.title} link={item.link} key={index}/> )}
                    {   authenticated && (
                        <button onClick={() => exitFunc()} className={styles["ItemLogOut"]}>
                            <span>Sair</span>
                            <IconExitDoor />
                        </button>
                    )}
                </div>
            ) }
        </header>
    )
}

export default NavBar;