import DetoxImage from '../../../assets/TratamentosImages/Corporal/Detox.jpg';
import EstriasImage from '../../../assets/TratamentosImages/Corporal/Estrias.jpg';
import DrenagemImage from '../../../assets/TratamentosImages/Corporal/Drenagem.jpg';
import FlacidezImage from '../../../assets/TratamentosImages/Corporal/Flacidez.jpg';
import MassagemImage from '../../../assets/TratamentosImages/Corporal/Massagem.jpg';
import OndasDeChoque from '../../../assets/TratamentosImages/Corporal/OndasDeChoque.jpg';
import UltraCavitacaoImage from '../../../assets/TratamentosImages/Corporal/Cavitação.jpg';
import EndermologiaImage from '../../../assets/TratamentosImages/Corporal/Endermologia.jpg';
import HidrolipoclasiaImage from '../../../assets/TratamentosImages/Corporal/Hidrolipoclasia.jpg';
import VentosaterapiaImage from '../../../assets/TratamentosImages/Corporal/Ventosaterapia.jpg';
import RadiofrequenciaImage from '../../../assets/TratamentosImages/Corporal/Radiofrequencia.jpg';
import GorduraLocalizadaImage from '../../../assets/TratamentosImages/Corporal/GorduraLocalizada.jpg';
import UltraCavitacaoFrequencia from '../../../assets/TratamentosImages/Corporal/UltraCavitaçãoComRadioFrequencia.jpg'

import { SectionTitle, ServiceItem, Pagination, NavBar, Footer } from "../../../components"


const Tratamentos = () => {
    const elements = 9;
    const TratamentosItems = [
        <ServiceItem ideia={"Foto do serviço de Endermologia"}      title={"Endermologia"}   imageLink={EndermologiaImage} description={"Técnica que utiliza o vácuo para tratamento da gordura localizada e celulite, tonifica a pele e melhora a circulação local. A endermologia também é usada para descolar uma cicatriz aderida."} />, 
        <ServiceItem ideia={"Foto do serviço de ultracavitação"}    title={"Ultracavitação"} imageLink={UltraCavitacaoImage} description={"Aparelho de ultrassom cavitacional que emite ondas sonoras que quebram células de gordura liberando ácidos graxos livres que o organismo reaproveita como energia. Famosa lipo sem corte, a ultracavitação é uma excelente opção para tratar gordura localizada."} />, 
        <ServiceItem ideia={"Foto do serviço de ultracavitação com radiofrequencia "} title={"Ultracavitação Com Radiofrequência"} imageLink={UltraCavitacaoFrequencia} description={"A ultracavitação combinada com radiofrequência é um avançado tratamento estético que realizamos. A ultracavitação utiliza ondas sonoras para quebrar células de gordura, liberando ácidos graxos que o corpo transforma em energia. A radiofrequência, por sua vez, promove a firmeza da pele, resultando em uma opção eficaz e não invasiva para tratar gordura localizada."} />, 
        <ServiceItem ideia={"Foto do serviço de Tratamento de Choque"}title={"Ondas de Choque"}   imageLink={OndasDeChoque} description={"Desperte a renovação e melhore a vitalidade da sua pele com nosso inovador tratamento de ondas de choque. Essa tecnologia avançada utiliza pulsos acústicos para estimular a regeneração celular, promovendo a produção de colágeno e melhorando a elasticidade da pele."} />, 
        <ServiceItem ideia={"Foto do serviço de Detox Corporal"}    title={"Detox corporal"} imageLink={DetoxImage} description={"Protocolo exclusivo que combina técnicas manuais como a drenagem e a modeladora,  associado a eletroterapia e manta térmica. O detox promove relaxamento, redução do inchaço e aumento da circulação corporal, além de melhorar o contorno corporal. "} />, 
        <ServiceItem ideia={"Foto do serviço de Ventosaterapia"}    title={"Ventosaterapia"} imageLink={VentosaterapiaImage} description={"A Ventosaterapia, utilizando aparelho de emissão de correntes de alta frequência, proporciona um aquecimento tecidual suave, alcançando até 42 graus. Esse estímulo promove a produção de colágeno, oferecendo benefícios notáveis para a saúde da pele. Indicada para tratamento de rugas, flacidez, celulite, estrias, cicatrizes e gordura localizada."} />, 
        <ServiceItem ideia={"Foto do serviço de Estrias"}           title={"Estrias"}        imageLink={EstriasImage} description={"Striort é um tratamento patenteado não-invasivo que usa cosméticos ortomoleculares para o combate a todos os tipos de estrias (rosas ou vermelhas, hiperpigmentadas ou brancas). sem causar dor à paciente."} />, 
        <ServiceItem ideia={"Foto do serviço de radiofrequencia"}   title={"Radiofrequência Corporal"}imageLink={RadiofrequenciaImage} description={"Aparelho de emissão de correntes de alta frequência que promove aquecimento tecidual chegando até 42 graus estimulando assim a produção de colágeno.  Indicada para o tratamento de rugas, flacidez, celulite, estrias, cicatrizes e gordura localizada. "} />, 
        <ServiceItem ideia={"Foto do serviço de drenagem linfática"}title={"Drenagem linfática"}                 imageLink={DrenagemImage} description={"Relaxe com nossa terapia de drenagem linfática, um tratamento suave e eficaz projetado para promover a saúde e o bem-estar. A técnica especializada utiliza movimentos suaves para estimular o sistema linfático, auxiliando na eliminação de toxinas e reduzindo o inchaço. Além de proporcionar uma sensação imediata de leveza, a drenagem linfática contribui para melhorar a circulação, aliviar tensões e realçar a luminosidade da pele."} />, 
        <ServiceItem ideia={"Foto do serviço de flacidez"}          title={"Tratamento para Flacidez"}           imageLink={FlacidezImage} description={"Explore nosso tratamento especializado para combater a flacidez e restaurar a firmeza da sua pele. Com tecnologias avançadas, nosso protocolo visa estimular a produção de colágeno, promovendo uma pele mais tonificada e rejuvenescida. Indicado para diversas áreas do corpo, nosso tratamento para flacidez é a solução ideal para quem busca uma aparência mais firme e jovem."} />, 
        <ServiceItem ideia={"Foto do serviço de hidrolipoclasia"}   title={"Hidrolipoclasia não aspirativa"}     imageLink={HidrolipoclasiaImage} description={"Experimente nossa abordagem inovadora com a Hidrolipoclasia não aspirativa, um procedimento avançado para modelagem corporal. Sem a necessidade de aspiração, esse tratamento utiliza uma solução especial para romper as células de gordura, permitindo uma eliminação natural pelo corpo. Indicada para áreas específicas, a Hidrolipoclasia não aspirativa oferece resultados visíveis, promovendo a redução de medidas de forma eficaz."} />, 
        <ServiceItem ideia={"Foto do serviço de massagem relaxante"} title={"Massagem relaxante e desportiva"}    imageLink={MassagemImage} description={"Desfrute do melhor em relaxamento e revitalização com nossas exclusivas massagens relaxante e desportiva. Nossa massagem relaxante é projetada para aliviar o estresse, relaxar músculos tensos e proporcionar uma sensação geral de tranquilidade. Por outro lado, a massagem desportiva é focada na recuperação muscular, aumentando a flexibilidade e aliviando a tensão resultante de atividades físicas intensas."} />, 
        <ServiceItem ideia={"Foto do serviço de tratamento de gordura"} title={"Tratamento para gordura localizada"} imageLink={GorduraLocalizadaImage} description={"Nosso avançado tratamento para gordura localizada é projetado para oferecer resultados notáveis. Utilizando tecnologias inovadoras, focamos na quebra eficiente das células de gordura, promovendo a liberação de ácidos graxos que o corpo converte em energia. Este tratamento não invasivo é especialmente indicado para quem busca redução de medidas e contornos mais definidos."} />, 
    ]

    return(
        <>
            <NavBar/>
            <SectionTitle title={"Corporal"}/>
            <div style={{margin: "25px 0"}}>
                <Pagination items={TratamentosItems} elementsPerPage={elements}/>
            </div>
            <Footer/>
        </>
    )
}

export default Tratamentos