import React from 'react';
import AppRoutes from './AppRoutes';
import styles from './App.module.scss';

const App = () => {
  return (
    <div className={styles["App"]}>
      <AppRoutes/>
    </div>
  );
}

export default App