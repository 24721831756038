import styles from './LiaAbout.module.scss';
import mainImage from '../../../assets/Uli fotos/_MG_1650.jpg';
import { Footer, NavBar, SectionTitle } from "../../../components";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const LiaSobreComplete = () => {
    return(
        <>
        <NavBar/>
        <SectionTitle title={"EquipeUli"}/>
        <div className={styles["Container"]}>
            <div className={styles["Content"]}>
                <div className={styles["MobileHeader"]}>
                    <div>DRA</div>
                    <div>LIA</div>
                    <div>RODRIGUES</div>
                </div>
                <LazyLoadImage 
                    effect='blur'
                    loading={"lazy"} 
                    src={mainImage} className={styles["Picture"]} alt={"imagem da profisional"}/>
                <div className={styles["SecondColumn"]}>
                    <div className={styles["FirstText"]}>
                        <div>DRA</div>
                        <div>LIA RODRIGUES</div>
                        <div>Fisioterapeuta altamente experiente e dedicada, com mais de 13 anos de atuação na área de Fisioterapia Dermatofuncional e Estética. Busco oferecer serviços personalizados e resultados de qualidade para minhas pacientes que desejam melhorar sua aparência e autoestima. Tenho paixão pela indústria da beleza e estou comprometida em utilizar minhas habilidades e conhecimentos para ajudar minhas pacientes e alcaçar seus objetivos estéticas de maneira segura e eficaz.</div>
                    </div>
                    <div className={styles["SecondText"]}>
                        <div>Qualificações:</div>
                        <div>Fisiotepeuta formada pela Universidade de Fortaleza - 2009.</div>
                        <div>Especialização em Fisioterapia Dermatofuncional pela Universidade de Fortaleza - 2012</div>
                        <div>Mestranda em Fisioterapia e Funcionalidade pela Universidade Federal do Ceará - atual</div>
                        <div>Formação em Drenagem Linfática Manual Corporal e Facial método Leduc - 2010</div>
                        <div>Licenciada pela técnica de Hidrolipoclasia pelo Cursos Talita Bessa - 2020</div>
                        <div>Formação em Ozonioterapia em disfunções estéticas - 2020</div>
                        <div>Formação em Mesoterapia Injetável Facial e Corporal - 2022</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default LiaSobreComplete;