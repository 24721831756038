import { Link } from 'react-router-dom';
import styles from './Summary.module.scss';
import { useEffect, useState } from 'react';
import AboutMobile from '../AboutMobile/AboutMobile';

import Luanda from '../../assets/Uli fotos/_MG_1625.jpg';
import Lia from '../../assets/Uli fotos/_MG_1665.jpg';

const Summary = () => {

    const [ windowSize, setWindowSize ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    
    const updateWindowSize = () => {
    setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    };
    
    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);

        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    return(
        <div className={styles["Container"]}>
            <div className={styles["FirstColumn"]}>
                <span className={styles["Ideia"]}>Conheça a Uli Clinic</span>
                <h3 className={styles["MainTitle"]}>Um espaço dedicado à sua beleza e bem-estar</h3>
                <p className={styles["MainText"]}>
                    Somos uma clinica de fisioterapia dermatofuncional e estética que oferece uma variedade de tratamentos para melhorar a aparência e a saúde da pele e do corpo. A nossa equipe de profissionais altamente qualificados está comprometida em proporcionar aos nossos clientes os melhores resultados possíveis.
                </p>
                <Link to={"/clinica"} className={styles["SaibaMaisButton"]} onClick={() => window.scroll(0,0)}>Saiba Mais</Link>
            </div>
            <div className={styles["LastColumn"]}>
                <div className={styles["ColumnImage"]} style={{backgroundImage: `url(${Lia})`,}}></div>
                <div className={styles["ColumnImage"]} style={{backgroundImage: `url(${Luanda})`,}}></div>
            </div>
            {   windowSize.width < 800 && (
                <div className={styles["ContainerMobAbouts"]}>
                <AboutMobile 
                    imageUrl={Lia}
                    theme='light'
                    buttonRoute='/sobre/Lia'
                    buttonText='Saiba Mais'
                    personRole='Fisioterapeuta'
                    personName='Lia Rodrigues'/>
                <AboutMobile 
                    imageUrl={Luanda}
                    theme='dark'
                    buttonText='Saiba Mais'
                    personRole='Fisioterapeuta'
                    buttonRoute='/sobre/Luanda'
                    personName='Luanda Henrique'/>
                </div>
            )}
        </div>
    )
}

export default Summary;