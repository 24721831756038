import styles from '../LiaAbout/LiaAbout.module.scss';
import mainImage from '../../../assets/Uli fotos/Marcia4.jpeg';
import { Footer, NavBar, SectionTitle } from "../../../components";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const MarciaSobreComplete = () => {
    return(
        <>
        <NavBar />
        <SectionTitle title={"EquipeUli"}/>
        <div className={styles["Container"]}>
            <div className={styles["Content"]}>
                <div className={styles["MobileHeader"]}>
                    <div>DRA</div>
                    <div>Marcia Izadora</div>
                </div>
                <LazyLoadImage 
                    effect='blur'
                    loading={"lazy"} 
                    src={mainImage} className={styles["Picture"]} alt={"imagem da profisional"}/>
                <div className={styles["SecondColumn"]}>
                    <div className={styles["FirstText"]}>
                        <div>DRA</div>
                        <div>Marcia Izadora</div>
                        <div>Esteticista e Cosmetóloga  e especialista em design de sobrancelha e micropigmentação!
                        transformo mulheres reais desde 2012, que foi quando iniciei no mundo do design de sobrancelha e a cada dia sou apaixonada, principalmente quando vejo o sorriso de cada uma delas após cada atendimento e sempre quis entregar o meu melhor para cada mulher que passou e passa por minhas mãos, assim em 2016 me encontrei na estética, que foi onde aperfeiçoei meus conhecimentos. Em 2020 me especializei em micropigmentação de sobrancelhas e lábios. Além de transformar eu renovo mulheres!
                        </div>
                    </div>
                    <div className={styles["SecondText"]}>
                        <div>Qualificações:</div>
                        <div>Esteticista e Cosmetologa - UNIFAMETRO</div>
                        <div>Designer de Sobrancelha - JESSICA SOARES ACADEMY</div>
                        <div>Micropigmentação de Sobrancelha e Lábios - RAQUEL BARROS ACADEMY</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default MarciaSobreComplete;