import lia from "../../assets/Uli fotos/_MG_1651.jpg";
import Luanda from '../../assets/Uli fotos/_MG_1618.jpg';
import Marcia from '../../assets/Uli fotos/Marcia1.jpeg';

import { Footer, NavBar } from "../../components";
import { Pagination, SectionTitle, AboutMobile } from "../../components";

const EquipeUli = () => {

    const people = [
        <AboutMobile theme="light" personName="Lia Rodrigues" buttonRoute='/sobre/Lia' buttonText='Saiba Mais' personRole='Fisioterapeuta' imageUrl={lia}/>, 
        <AboutMobile theme='dark' buttonText='Saiba Mais' personRole='Fisioterapeuta' buttonRoute='/sobre/Luanda' personName='Luanda Henrique'  imageUrl={Luanda}/>,
        <AboutMobile theme="light" personName="Marcia Izadora" buttonRoute='/sobre/Marcia' buttonText='Saiba Mais' personRole='Esteticista' imageUrl={Marcia}/>, 
    ]

    return(
        <>
            <NavBar/>   
            <SectionTitle title="Equipe Uli"/>
            <Pagination elementsPerPage={9} items={people}/>
            <Footer/>
        </>
    )
}

export default EquipeUli;