import { Link } from 'react-router-dom'
import styles from './ServicoItem.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'


interface ServicePropsInterface {
    title: string,
    imageLink: string,
    description: string,
    ideia?: string
}

/**
 * Componente que representa uma amostra dos serviços que são ofertados pela clinica
 * 
 * @param title é o titulo do serviço.
 * @param description é a descrição do serviço ofertado.
 * @param imageLink é o link para uma imagem dentro do projeto.
 * @param ideia é a principal ideia para leitores de tela (quesito acessibilidade)
 * @returns Um JSX.Element com todas as propriedades de entrada.
 */


const ServiceItem = ( { title, imageLink, description, ideia } : ServicePropsInterface ) => {
    return(
        <div className={styles["ServiceItemContainer"]}>
            <LazyLoadImage 
                effect='blur'
                loading={"lazy"} 
                src={imageLink} className={styles["ImageProps"]} alt={ideia}/>
            <div className={styles['ServiceDescribeContainer']}>
                <div className={styles['ServiceHeader']}>
                    <span>{title}</span>
                </div>
                <div className={styles['DescriptionContainer']}>
                    <span>{description}</span>
                </div>
                <div className={styles['ButtonContainer']}>
                    <Link className={styles['ButtonStyle']}
                        to={`https://api.whatsapp.com/send?phone=5585991791916&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20o%20tratamento%20de%20${title}%20com%20voc%C3%AAs%20%F0%9F%A4%A9`}
                        onClick={() => window.scroll(0,0)} >
                        Agendar Horário
                    </Link>
                    </div>
            </div>
        </div>
    )
}

export default ServiceItem;