import { useState } from "react";
import { AuthContextProvider } from "./Contexts";
import { ToastContainer, WhatsLink } from "./components";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ContextModalAddOff, ModalDeleteAddI, ModalItemAddT } from "./Contexts/OffContexts/ModalAddOff";
import { ContextModalDeleteOff, ModalDeleteOffI, ModalItemDeleteT } from "./Contexts/OffContexts/ModalDeleteOff";
import { 
    Home, 
    Login, 
    Clinica,
    NotFound,
    EquipeULI, 
    OndeEstamos, 
    Tratamentos,
    TratamentoFacial, 
    LiaCompleteAbout,
    TratamentoCorporal, 
    LuandaCompleteAbout, 
    LiaAlternativeAbout, 
    TratamentoOperatorio,
    LuandaAlternativeAbout } from "./pages";
import MarciaAboutMinimal from "./pages/EquipeUli/Marcia/SobreAlternativa";
import MarciaSobreComplete from "./pages/EquipeUli/Marcia/SobreComplete";


const Router = createBrowserRouter([
    { path: "/", element: <Home/>},
    { path: "*", element: <NotFound/>},
    { path: "/login", element: <Login />},
    { path: "/clinica", element: <Clinica/>},
    { path: "/equipeuli", element: <EquipeULI/>},
    { path: "/tratamentos", element: <Tratamentos/>},
    { path: "/onde-estamos", element: <OndeEstamos/>},

    { path: "/sobre/Lia", element: <LiaAlternativeAbout/>},
    { path: "/sobre/Lia/Complete", element: <LiaCompleteAbout/>},
    
    { path: "/sobre/Luanda", element: <LuandaAlternativeAbout/>},
    { path: "/sobre/Luanda/Complete", element: <LuandaCompleteAbout/>},

    { path: "/sobre/Marcia", element: <MarciaAboutMinimal/>},
    { path: "/sobre/Marcia/Complete", element: <MarciaSobreComplete/>},
    
    { path: "/tratamentos/facial", element: <TratamentoFacial/>},
    { path: "/tratamentos/corporal", element: <TratamentoCorporal/>},
    { path: "/tratamentos/posOperatorio", element: <TratamentoOperatorio/>},
])

const AppRoutes = () => {
    
    const [ isOpenModalDeleteOff, setRenderModalDeleteOff ] = useState<ModalItemDeleteT>(
        { renderModal : false }
    );

    const [ isOpenModalAddOff, setRenderModalAddOff ] = useState<ModalItemAddT>(
        { renderModal: false, }
    );

    const initialValueDeleteOff : ModalDeleteOffI & ModalItemDeleteT = {
        id : isOpenModalDeleteOff?.id,
        setRenderModal : setRenderModalDeleteOff,
        renderModal : isOpenModalDeleteOff?.renderModal,
    };

    const initialValueAddOff : ModalDeleteAddI & ModalItemAddT  = {
        id: isOpenModalAddOff?.id,
        setRenderModal : setRenderModalAddOff,
        renderModal : isOpenModalAddOff?.renderModal,
    }

    return(
        <AuthContextProvider>
            <ContextModalAddOff.Provider value={initialValueAddOff}>
                <ContextModalDeleteOff.Provider value={initialValueDeleteOff}>
                    <ToastContainer />
                    <RouterProvider router={Router}/>
                    <WhatsLink/>
                </ContextModalDeleteOff.Provider>
            </ContextModalAddOff.Provider>
        </AuthContextProvider>
    )
}

export default AppRoutes