import styles from './NotFound.module.scss'
import { Footer, NavBar, SectionTitle } from '../../components'

const NotFound = () => {
    return(
        <>
            <NavBar />
            <SectionTitle title={"Not Found"}/>
            <div className={styles['MainContainer']}>A página que você está procurando não foi encontrada</div>
            <Footer/>
        </>
    )
}

export default NotFound