import { useContext } from 'react';
import { AuthContext } from '../../Contexts';
import styles from './SectionTitle.module.scss';


interface SectionTitleProps{
    title: string;
    children?: JSX.Element;
    stylesProp?: {},
}

const SectionTitle = ({title, children, stylesProp}: SectionTitleProps) => {
    
    const { authenticated } = useContext(AuthContext);
    
    return(
        <div className={`${styles[ (authenticated && children !== undefined ) ? "SectionContainerAdmin" : "SectionContainer" ] }`}>
            <div className={`${styles[ (authenticated && children !== undefined ) ? "SectionAdminContent" : "SectionContent" ] }`}>
                <span className={styles['TitleSection']} style={stylesProp}>
                    {title}
                </span>
                {   authenticated && (
                    children
                )}
            </div>
        </div>
    )
}

export default SectionTitle;