import lia from "../../../assets/Uli fotos/_MG_1642.jpg";
import { AlternativeInfo, Footer, NavBar } from "../../../components";


const LiaAboutMinimal = () => {

    const Descricoes = [
        {
        titleInfo : "Gestantes e Puérperas",
        description : "Como mãe, criei um protocolo exclusivo desde a gestação, com drenagens adaptadas. Pode-se aplicar antes do parto, aplico tappings e laserterapia, seguidos de tratamento para flacidez abdominal, incluindo exercícios hipopressivos para diástase."
    },{   
        titleInfo : "Mesoterapia",
        description : "Sou treinada para trabalhar com injetáveis como enzimas e skinbooster para tratar gordura localizada e flacidez, tanto corporais quanto faciais."
    },{
        titleInfo : "Hidrolipoclasia não-aspirativa",
        description : "Sou licenciada para aplicar essa técnica que é minimamente invasiva para tratamento de gordura localizada associada à eletroterapia, entregando resultados rápidos e satisfatórios."
    },{
        titleInfo : "Pós-operatório",
        description : "Com especialização em procedimentos faciais e corporais de pós operatório, posso combater as principais intercorrências nos protocolos de cirurgia plástica."
    }, 
]

    return(
        <>
            <NavBar/>
            <AlternativeInfo Descricoes={Descricoes} RotaButton={"/sobre/Lia/Complete"} ImagemPath={lia} Titulo={"Como cuido de você?"}/>    
            <Footer/>
        </>
    )
}

export default LiaAboutMinimal;