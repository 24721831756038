import Marcia from "../../../assets/Uli fotos/Marcia5.jpeg";
import { AlternativeInfo, Footer, NavBar } from "../../../components";


const MarciaAboutMinimal = () => {

    const Descricoes = [
        {   titleInfo : "Micropigmentação labial",
            description : "Trabalho com técnica de micropigmentação que consiste em uma aplicação de um pigmento sobre a epiderme que restaura a coloração da pele na região e define o contorno dos lábios."
    }, {
        titleInfo : "Drenagem Linfática",
        description : "Sou especialista em terapias manuais, e o tratamento corporal mais realizado na Uli é a drenagem linfática, podendo ser adaptada para as pacientes de pós cirurgicos e gestantes. A drenagem tem o objetivo de diminuir edemas (inchaços), melhorar a circulação local e promover relaxamento."
    }, {
        titleInfo : "Brows Lamination",
        description : "Sobrancelha sempre arrumada, preenchida e com aspecto de sempre cheia. Essa técnica é uma ótima opção para quem tem sobrancelha fina, com falhas e com poucos pelos."
    }, {
        titleInfo : "Design e micropigmentação de sobrancelhas",
        description : "Transformo olhares através do Design corrigindo falhas, melhorando o contorno e simetria, deixando o olhar mais atraente e encantador! Trabalho com tratamento a longo prazo que seria a reconstrução somente com Design de Sobrancelhas e com a opção de aplicação de Henna ou com o tratamento a curto prazo com a micropigmentação nas técnicas fio a fio e shadow."
    }]

    return(
        <>
            <NavBar/>
            <AlternativeInfo Descricoes={Descricoes} RotaButton={"/sobre/Marcia/Complete"} ImagemPath={Marcia} Titulo={"Como cuido de você?"}/>    
            <Footer/>
        </>
    )
}

export default MarciaAboutMinimal;