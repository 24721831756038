import { Link } from 'react-router-dom'
import styles from './AboutMobile.module.scss'


type AboutMobileProps = {
    buttonText: string,
    personName: string,
    personRole: string,
    imageUrl: string,
    buttonRoute: string,
    theme: 'dark' | 'light'
}

/**
 * @param imageUrl - It must receive a variable from an import or an url from the web:
 * 
 * ```js
 * import image from "/assets/Logos/Logo1.png";
 * const url = "https://example.com";
 * ``` 
 * 
 * Here is an example of use:
 * ```js
 * import lia from "/assets/Logos/Logo1.png"
 * 
 * <AboutMobile
 *      buttonRoute="/"
 *      buttonText="Saiba Mais"
 *      imageUrl={lia} // or imageUrl={"http://example.com/imageExample"}
 *      personName="Lia Rodrigues"
 *      personRole="Esteticista"
 *      theme="light"
 * />
 * ```
 */

const AboutMobile = (props: AboutMobileProps) => {

    const themes = {
        "light": {
            "SquareBackgroundColor": "#F3EFEC",
            "SquareFontColor": "#473527",
            "ButtonFontColor": "#473527",
            "ButtonBackgroundColor": "#C69874"
        },

        "dark": {
            "SquareBackgroundColor": "#473527",
            "SquareFontColor": "#F3EFEC",
            "ButtonFontColor": "#F3EFEC",
            "ButtonBackgroundColor": "#C69874" 
        }
    }

    return(
        <div className={styles['Container']}>
            <div className={styles['PictureContainer']}>
                <div className={styles['Picture']} style={{backgroundImage: `url(${props.imageUrl})`}}></div>
            </div>

            <div className={styles['Square']} style={{backgroundColor: themes[props.theme].SquareBackgroundColor, color: themes[props.theme].SquareFontColor}}>
                <div className={styles['Text']}>
                    <p className={styles['Name']}>{props.personName}</p>
                    <p className={styles['Title']}>{props.personRole}</p>
                </div>

                <div className={styles['AboutButton']}>
                    <Link to={props.buttonRoute} onClick={() => window.scroll(0,0)}><button style={{backgroundColor: themes[props.theme].ButtonBackgroundColor, color: themes[props.theme].ButtonFontColor}}>{props.buttonText}</button></Link>
                </div>
            </div>
        </div>
    )
}

export default AboutMobile