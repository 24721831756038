import styles from '../LiaAbout/LiaAbout.module.scss';
import mainImage from '../../../assets/Uli fotos/_MG_1689.jpg';
import { Footer, NavBar, SectionTitle } from "../../../components";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const LuandaSobreComplete = () => {
    return(
        <>
        <NavBar />
        <SectionTitle title={"EquipeUli"}/>
        <div className={styles["Container"]}>
            <div className={styles["Content"]}>
                <div className={styles["MobileHeader"]}>
                    <div>DRA</div>
                    <div>LUANDA</div>
                    <div>HENRIQUE</div>
                </div>
                <LazyLoadImage 
                    effect='blur'
                    loading={"lazy"} 
                    src={mainImage} className={styles["Picture"]} alt={"imagem da profisional"}/>
                <div className={styles["SecondColumn"]}>
                    <div className={styles["FirstText"]}>
                        <div>DRA</div>
                        <div>LUANDA HENRIQUE</div>
                        <div>Fisioterapeuta altamente experiente e dedicada, com mais de 13 anos de atuação na área de Fisioterapia Dermatofuncional e Estética. Busco oferecer serviços personalizados e resultados de qualidade para minhas pacientes que desejam melhorar sua aparência e autoestima. Tenho paixão pela indústria da beleza e estou comprometida em utilizar minhas habilidades e conhecimentos para ajudar minhas pacientes e alcançar seus objetivos de maneira segura e eficaz. Trabalho em equipe com dermatologistas e cirurgiões plásticos para dar suporte às pacientes nos tratamentos estéticos e também no acompanhamento de pré e pós-operatório. </div>
                    </div>
                    <div className={styles["SecondText"]}>
                        <div>Qualificações:</div>
                        <div>Fisioterapeuta formada pela Universidade de Fortaleza - 2010</div>
                        <div>Especialização em Fisioterapia Dermatofuncional pela Universidade de Fortaleza - 2012</div>
                        <div>Formação em Drenagem Linfática Manual Corporal e Facial método Leduc - 2010</div>
                        <div>Licenciada pelo Método Fibrose Zero padrão ouro - 2019.</div>
                        <div>Licenciada pela Técnica Striort - 2019</div>
                        <div>Licenciada pela técnica de Hidrolipoclasia pelo Cursos Talita Bessa - 2020</div>
                        <div>Licenciada pela Técnica Striort - 2019</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default LuandaSobreComplete;